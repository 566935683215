
export class Config {

    static organizationId = '656a6b6f527bfd0edb8214dd';
    static organizationName = "ATCO Eagle Gold Mine";
    static appVersion = '2.34.00';

    static cipher: string = 'KPdzpXFGaUkAFHwj5FBy82B6'; 

    static isCanuucan = 'false';

    static pickupTypes = [ 'DELIVERYTYPE02', 'DELIVERYTYPE07' ];
    static zendeskAccountUrl = 'moduurnhelp.zendesk.com';
    static zendeskUserField=1500009211882;
    static zendeskUserFormId= 1500002580982;
    
    constructor() { }
}
